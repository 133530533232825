import React, { useState, useEffect } from "react"
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

type CssStyle = {
  fontSize: string
  lineHeight: string
}

type CustomStyle = {
  name: string
  style: CssStyle
}

const stylelLabels = [
  { name: "body", label: "Normal" },
  { name: "h1", label: "Heading 1" },
  { name: "h2", label: "Heading 2" },
  { name: "h3", label: "Heading 3" },
  { name: "h4", label: "Heading 4" },
  { name: "h5", label: "Heading 5" },
  { name: "h6", label: "Heading 6" },
]

const initialStyles = stylelLabels.map((label) => {
  return { name: label.name, style: { fontSize: "", lineHeight: "" } }
})

/**
 * Picker for custom styles
 * @param props - branding props
 */
const BrandingStylesPicker = (props: any) => {
  const { branding, onPropertyChange } = props

  const [styles, setStyles] = useState<CustomStyle[]>(initialStyles)

  const [custom, setCustom] = useState(false)

  // Effect to initialize styles from branding object
  useEffect(() => {
    if (!branding) {
      return
    }

    if (branding.styles) {
      const updatedStyles = styles.map((style) => {
        const foundStyle = branding.styles.find(
          (s: any) => s.name === style.name
        )
        return foundStyle ? foundStyle : style
      })
      setStyles(updatedStyles)
    }

    setCustom(branding.size === "custom")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branding])

  /**
   * Update branding structure for a change
   * @param index - index of custom branding
   * @param field - branding field to set
   * @param value = value for branding field
   */
  const handleStyleChange = (
    index: number,
    field: keyof CssStyle,
    value: string
  ) => {
    const newStyles = [...styles]
    newStyles[index] = {
      ...newStyles[index],
      style: {
        ...newStyles[index].style,
        [field]: value,
      },
    }
    newStyles[index].style[field] = value
    setStyles(newStyles)
    onPropertyChange("styles", newStyles)
  }

  if (!custom) {
    return null
  } else {
    return (
      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Stack sx={{ minWidth: "800px" }}>
          {styles.map((style, index) => (
            <Box key={style.name} mb={2}>
              <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                <Grid item xs={2} container alignItems="center">
                  <Typography variant="titleSmall" sx={{ width: "100%" }}>
                    {
                      stylelLabels.find((label) => label.name === style.name)
                        ?.label
                    }
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <InputLabel id={`font-size-label-${style.name}`}>
                      Font Size
                    </InputLabel>

                    <Select
                      labelId={`font-size-label-${style.name}`}
                      value={style.style.fontSize || ""}
                      onChange={(e) =>
                        handleStyleChange(index, "fontSize", e.target.value)
                      }
                      label="Font Size"
                    >
                      <MenuItem value="">Default</MenuItem>
                      <MenuItem value="8px">8</MenuItem>
                      <MenuItem value="9px">9</MenuItem>
                      <MenuItem value="10px">10</MenuItem>
                      <MenuItem value="11px">11</MenuItem>
                      <MenuItem value="12px">12</MenuItem>
                      <MenuItem value="14px">14</MenuItem>
                      <MenuItem value="18px">18</MenuItem>
                      <MenuItem value="24px">24</MenuItem>
                      <MenuItem value="30px">30</MenuItem>
                      <MenuItem value="36px">36</MenuItem>
                      <MenuItem value="48px">48</MenuItem>
                      <MenuItem value="60px">60</MenuItem>
                      <MenuItem value="72px">72</MenuItem>
                      <MenuItem value="96px">96</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <InputLabel id={`line-spacing-label-${style.name}`}>
                      Line Spacing
                    </InputLabel>

                    <Select
                      labelId={`line-spacing-label-${style.name}`}
                      value={
                        style.style.lineHeight === "" ||
                        style.style.lineHeight === "default"
                          ? ""
                          : style.style.lineHeight
                      }
                      onChange={(e) =>
                        handleStyleChange(index, "lineHeight", e.target.value)
                      }
                      label="Line Spacing"
                    >
                      <MenuItem value="">Default</MenuItem>
                      <MenuItem value="1">Single</MenuItem>
                      <MenuItem value="1.15">1.15</MenuItem>
                      <MenuItem value="1.5">1.5</MenuItem>
                      <MenuItem value="2">Double</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Stack>
      </Box>
    )
  }
}

export default BrandingStylesPicker
