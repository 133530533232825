import { useEffect, useState } from "react"
import {
  BuildCircle,
  Cached,
  Close,
  CloudDone,
  CloudOff,
  CloudQueue,
  CloudSync,
  FileDownload,
  FindReplace,
  Help,
  History,
  LibraryBooks,
  Psychology,
  Share,
} from "@mui/icons-material"
import {
  AppBar,
  Toolbar,
  Stack,
  Link,
  Menu,
  MenuItem,
  Typography,
  ListItemIcon,
  Divider,
} from "@mui/material"
import { Box } from "@mui/system"
import * as api from "../../api"
import useEditorState, { SAVE_STATE } from "../../hooks/useEditorState"
import useRemarksCount from "../../hooks/useRemarksCount"
import Editor from "../widgets/Editor"
import IconButton from "../atoms/IconButton"
import TitleTextField from "../widgets/TitleTextField"
import LearnAdvisorDrawer from "../widgets/LearnAdvisor/LearnAdvisorDrawer"
import useDrawerState from "../../hooks/useDrawerState"
import makeShareSettingsHref from "../../utilities/makeShareSettingsURL"
import NotFoundScreen from "./NotFoundScreen"
import { ContextualMenu } from "../widgets/LearnAdvisor/ContextualMenu"
import ErrorDialog from "../widgets/ErrorDialog"
import Outline from "../../features/outline/Outline"
import Drawer from "../atoms/Drawer"
import ActiveSectionProvider from "../../features/outline/context/ActiveSectionProvider"
import OutlineActions from "../../features/outline/OutlineActions"
import useOutlineCollapse from "../../features/outline/hooks/useOutlineCollapse"
import LearnAnalyzer from "../../features/outline/LearnAnalyzer"
import { GiveCourseIcon } from "../../assets/icons/customIcons"
import { GlobalStyles } from "@mui/material"
import { CustomerThemeProvider } from "../../contexts/customerTheme"
import useCommentListener from "../../hooks/useCommentListener"
import useRegisterFroalaCommands from "../../froala/useRegisterFroalaCommands"
import { UpdatesButton } from "../../features/intelligent-updates"
import { transformSelection } from "../../features/transform/transformSelection"
import { EditorScreenContext } from "./EditorScreenContext"

const getSaveIcon = (state) => {
  switch (state) {
    case SAVE_STATE.SAVING:
      return CloudSync
    case SAVE_STATE.UNSAVED:
      return CloudQueue
    case SAVE_STATE.OFFLINE:
      return CloudOff
    default:
      return CloudDone
  }
}

const EditorScreen = (props) => {
  const { courseId, readOnly } = props

  const {
    editorRef,
    error,
    handleDestroy,
    handleInitialized,
    handleModelChange,
    html,
    handleAddTemplateSection,
    saveCourse,
    saveState,
    title,
    updateDocument,
    editor,
    reactElements,
    displayReactElement,
    version,
    editorContainer,
  } = useEditorState(courseId, readOnly)

  // Mount custom Froala buttons and commands
  useRegisterFroalaCommands(displayReactElement)

  // Mount hook for intermittently cleaning up orphaned comments
  useCommentListener(html)

  //  Operations for collapsing and expanding outline
  const { handleCollapse, hasExpandAll, hasCollapseAll, outlineRef } =
    useOutlineCollapse()

  const drawers = useDrawerState({ outline: { permanentAbove: "lg" } })
  const { drawerProps } = drawers

  //Tools Menu
  const [toolsAnchorEl, setToolsAnchorEl] = useState(null)
  const isToolsMenuOpen = Boolean(toolsAnchorEl)

  const isNotFound = error?.response?.status === 404
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    setErrorMessage(error?.message)
  }, [error])

  let learnAdvisorBadge = useRemarksCount() ?? (
    <Cached style={{ fontSize: 12 }} />
  )

  return (
    <CustomerThemeProvider courseID={courseId}>
      <EditorScreenContext.Provider
        value={{ editor, displayReactElement, editorContainer }}
      >
        {/* Disable overflow from Froala Popups when on Editor Screen*/}
        <GlobalStyles styles={{ body: { overflow: "hidden" } }} />
        {reactElements}
        {isNotFound ? (
          <NotFoundScreen />
        ) : (
          <Box
            sx={{
              maxWidth: "100vw",
              maxHeight: "100vh",
              height: "100vh",
              overflow: "hidden",
              display: "flex",
            }}
          >
            <ActiveSectionProvider editor={editor}>
              <Drawer
                anchor="left"
                name="Outline"
                onToggle={() => drawers.toggle("outline")}
                {...drawerProps("outline")}
                toolbar={
                  <OutlineActions
                    readOnly={readOnly}
                    handleAddTemplate={(templateType) =>
                      handleAddTemplateSection(templateType)
                    }
                    handleCollapse={handleCollapse}
                    hasCollapseAll={hasCollapseAll}
                    hasExpandAll={hasExpandAll}
                    editor={editor}
                    updateDocument={updateDocument}
                  />
                }
              >
                <Outline
                  ref={outlineRef}
                  readOnly={readOnly}
                  editor={editor}
                  updateDocument={updateDocument}
                  sx={{ flex: "1 1 auto", overflowY: "auto" }}
                />
                <Divider />
                <LearnAnalyzer editor={editor} />
              </Drawer>
            </ActiveSectionProvider>

            <Box
              sx={{
                display: "flex",
                minWidth: 300,
                flexGrow: 1,
                flexShrink: 1,
                flexDirection: "column",
                position: "relative",
              }}
            >
              <AppBar
                color="lexWhite"
                position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
              >
                <Toolbar disableGutters sx={{ gap: 2 }}>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Link href="/" underline="none" variant="h5">
                    <img
                      style={{ marginTop: "10px" }}
                      src="/lex-logo.svg"
                      alt="Learn Experts Logo"
                      height="36px"
                    />
                  </Link>
                  <TitleTextField
                    readOnly={readOnly}
                    title={title}
                    onTitleChange={(title) => {
                      api.renameCourse(courseId, title)
                    }}
                  />
                  {!readOnly && (
                    <Stack direction="row" gap={0.5}>
                      <IconButton
                        title="Save"
                        onClick={saveCourse}
                        Icon={getSaveIcon(saveState)}
                      />
                      <IconButton
                        title="LearnAdvisor"
                        badge={learnAdvisorBadge}
                        onClick={() => drawers.toggle("detections")}
                        toggled={drawers.isOpen("detections")}
                      >
                        <Psychology />
                      </IconButton>

                      <UpdatesButton
                        data-cy="intelligent-updates"
                        course={{
                          id: courseId,
                          version,
                          title,
                          state: saveState,
                        }}
                      />
                      <IconButton
                        title="Create Microlearning"
                        Icon={LibraryBooks}
                        href={{
                          pathname: "/microlearning",
                          search: `?course=${courseId}`,
                          state: {
                            course: {
                              title,
                              id: courseId,
                            },
                          },
                        }}
                      />
                      <IconButton
                        title="Export Course"
                        href={{
                          pathname: "/export",
                          search: `?course=${courseId}`,
                        }}
                        Icon={FileDownload}
                      />
                      <IconButton
                        title="Tools"
                        onClick={(e) => {
                          setToolsAnchorEl(e.currentTarget)
                        }}
                      >
                        <BuildCircle />
                      </IconButton>

                      <Menu
                        anchorEl={toolsAnchorEl}
                        id="tools-menu"
                        open={isToolsMenuOpen}
                        onClose={() => {
                          setToolsAnchorEl(null)
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          href={{
                            pathname: "/history",
                            search: `?page=0&limit=20&filterCourse=${courseId}&back=edit&courseId=${courseId}&title=${title}`,
                          }}
                        >
                          <ListItemIcon>
                            <History fontSize="small" />
                          </ListItemIcon>
                          <Typography>Course History</Typography>
                        </MenuItem>

                        <MenuItem
                          replace={true}
                          href={makeShareSettingsHref(
                            { id: courseId, title },
                            { modal: true }
                          )}
                          onClick={() => {
                            setToolsAnchorEl(null)
                          }}
                        >
                          <ListItemIcon>
                            <Share fontSize="small" />
                          </ListItemIcon>
                          <Typography>Share</Typography>
                        </MenuItem>

                        <MenuItem
                          href={{
                            pathname: "/find",
                          }}
                        >
                          <ListItemIcon>
                            <FindReplace fontSize="small" />
                          </ListItemIcon>
                          <Typography>Global Find &amp; Replace</Typography>
                        </MenuItem>
                        <MenuItem
                          href={{
                            pathname: "/give",
                            search: courseId
                              ? `?course=${courseId}`
                              : undefined,
                          }}
                        >
                          <ListItemIcon>
                            <GiveCourseIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography>Give Course</Typography>
                        </MenuItem>
                      </Menu>

                      <IconButton title="Help Hub" href="/help" target="_blank">
                        <Help />
                      </IconButton>
                    </Stack>
                  )}
                  <IconButton
                    title="Close"
                    href="/"
                    Icon={Close}
                    color="default"
                  />
                  <Divider orientation="vertical" variant="middle" flexItem />
                </Toolbar>
              </AppBar>
              <Toolbar />

              <Editor
                sx={{ flex: "1 1 auto" }}
                ref={editorRef}
                onInitialize={handleInitialized}
                onDestroy={handleDestroy}
                model={html}
                onModelChange={handleModelChange}
                readOnly={readOnly}
                updateDocument={updateDocument}
              />
            </Box>
            <ContextualMenu
              readOnly={readOnly}
              editorRef={editorRef}
              onAddComment={() => {
                drawers.open("detections")
              }}
              onTransform={(range) => {
                transformSelection({
                  editor: editor,
                  selection: range,
                  displayReactElement: displayReactElement,
                  editorContainer: editorContainer,
                })
              }}
            />
            <LearnAdvisorDrawer
              anchor="right"
              readOnly={readOnly}
              onToggle={() => drawers.toggle("detections")}
              drawerProps={drawerProps("detections")}
            />
            <ErrorDialog
              message={errorMessage}
              onClose={() => setErrorMessage(null)}
            ></ErrorDialog>
          </Box>
        )}
      </EditorScreenContext.Provider>
    </CustomerThemeProvider>
  )
}

export default EditorScreen
