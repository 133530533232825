import { Add, Delete, Edit } from "@mui/icons-material"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  lighten,
  styled,
} from "@mui/material"
import { useState } from "react"
import { CustomElementConfig, RenderViewOptions } from "./CustomElements"
import produce from "immer"
import { useFitText } from "../hooks/useFitText"

import FroalaEditor from "react-froala-wysiwyg"
import { useFlag } from "../utilities/feature-management"
import _ from "lodash"
import { minimalFroalaConfig } from "./minimalFroalaConfig"
import { AltTextEditor } from "./AltTextEditor"
import audioElementStyle from "../features/insertAudio/audioElementStyle"
import { InsertImageDialog } from "../features/insertImage/InsertImageDialog"
import { ImageUpload } from "./ImageUpload"

/** Data representation of flip card grid state. */
export interface FlipCardGridData {
  cards: FlipCardData[]

  /** Background color of flip cards */
  backgroundColor?: "primary"
}

/** Data representation of flip card state. */
interface FlipCardData {
  /** Data of front of card. */
  front: FlipCardFaceData
  /** Data of back of card. */
  back: FlipCardFaceData
}

/**
 * Data representation of single flip card face state as html
 */
interface FlipCardFaceDataHtml {
  type: "html"

  /** HTML of card. */
  html: string
}

/** Data representation of single flip card face state as image
 */
interface FlipCardFaceDataImage {
  type: "image"

  /** Image URL of card */
  imageUrl: string

  /** Fit of image, as object-fit property of CSS.
   * scale-down (default) means the image is scaled down to fit within the card, preserving aspect ratio.
   * cover means the image is scaled up/down to cover the card, preserving aspect ratio.
   * We don't include "contain" because scaling up a small image to fit the card is not useful.
   * In both cases, the image is centered and is not enlarged.
   */
  imageFit: "scale-down" | "cover"

  /** Image alt text */
  imageAlt: string
}

type FlipCardFaceData = FlipCardFaceDataHtml | FlipCardFaceDataImage

/*
 * This is a custom element that renders a grid of flip cards.
 * Each card has a front and back side. The HTML representation
 * is stored as a section element with a data-component="flip-card-grid"
 * attribute. Each card is stored as a div with a data-component="flip-card".
 * The front and back of each card are stored as divs with data-component="flip-card-front"
 * and data-component="flip-card-back" attributes.
 * Sample HTML:
 * <section data-component="flip-card-grid">
 *   <div data-component="flip-card">
 *     <div data-component="flip-card-front">Front of card 1</div>
 *     <div data-component="flip-card-back">Back of card 1</div>
 *   </div>
 *   <div data-component="flip-card">
 *     <div data-component="flip-card-front">Front of card 2</div>
 *     <div data-component="flip-card-back">
 *       <img src="https://example.com/image.png" style="object-fit: cover" alt="Image alt text">
 *     </div>
 *   </div>
 * </section>
 *
 */
export const flipCardGridCustomElement: CustomElementConfig<FlipCardGridData> =
  {
    selector: "[data-component='flip-card-grid']",
    /**
     * Extract data from the light-DOM representation of the custom element
     * @param element The element to extract data from
     */
    getDataFromElement: (element) => {
      const cards = Array.from(
        element.querySelectorAll("[data-component='flip-card']")
      ).map((card) => {
        /** Helper function to get data from a face of a card.
         * @param face The face to get data from.
         */
        function getFaceData(face: HTMLElement | null): FlipCardFaceData {
          if (!face) {
            return { type: "html", html: "" }
          }
          const imageUrl =
            face.querySelector("img")?.getAttribute("src") ?? undefined
          if (imageUrl) {
            const imageFit: any =
              face.querySelector("img")?.style.objectFit ?? "scale-down"

            const imageAlt =
              face.querySelector("img")?.getAttribute("alt") ?? ""

            return { type: "image", imageUrl, imageFit, imageAlt }
          }
          const html = face.innerHTML ?? ""
          return { type: "html", html }
        }

        const front = getFaceData(
          card.querySelector("[data-component='flip-card-front']")
        )
        const back = getFaceData(
          card.querySelector("[data-component='flip-card-back']")
        )

        return { front, back }
      })

      const backgroundColor = element.dataset.backgroundColor as
        | "primary"
        | undefined

      return { cards, backgroundColor }
    },
    /**
     * Update the light-DOM representation of the custom element from the data
     * @param element The element to update
     * @param data The data to update the element with
     */
    updateElementFromData: (element, data) => {
      /** Helper function to render a face of a card as HTML.
       * @param face The face to render.
       */
      function renderFaceAsHtml(face: FlipCardFaceData) {
        if (face.type === "image" && face.imageUrl) {
          return `<img src="${face.imageUrl}" alt="${_.escape(
            face.imageAlt
          )}" style="object-fit: ${face.imageFit}">`
        } else if (face.type === "html") {
          // Preserve div by adding a <br> if it is empty
          return face.html || "<br>"
        }
        return ""
      }

      // Save contents
      element.innerHTML = `
        ${data.cards
          .map(
            (card) =>
              `<div data-component="flip-card">` +
              `<div data-component="flip-card-front">` +
              renderFaceAsHtml(card.front) +
              `</div>` +
              `<div data-component="flip-card-back">` +
              renderFaceAsHtml(card.back) +
              `</div>` +
              `</div>`
          )
          .join("")}`

      if (data.backgroundColor) {
        element.dataset.backgroundColor = data.backgroundColor
      } else {
        delete element.dataset.backgroundColor
      }
    },
    /**
     * Render the custom element in the editor
     * @param options Options for rendering the custom element
     * @returns The rendered custom element
     * @see CustomElementConfig
     */
    renderView: (options: RenderViewOptions<FlipCardGridData>) => {
      const { data, onDataChange, editor, element, withStyles, readOnly } =
        options
      return (
        <FlipCardGridCustomComponent
          editor={editor}
          data={data}
          onDataChange={onDataChange}
          element={element}
          withStyles={withStyles}
          readOnly={readOnly}
        />
      )
    },
  }

/** Style for flip cards */
const flipCardStyle = `
.flip-card-grid {
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
}

.flip-card-grid-cards {
  text-align: center;
  flex: 1;
}

.flip-card-grid .buttons {
  visibility: hidden;
}

.flip-card-grid:hover .buttons {
  visibility: visible;
}

.flip-card {
  background-color: transparent;
  height: 283px;
  width: 255px;
  perspective: 1000px;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
}

/* Do an horizontal flip when set as a class */
.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-content {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-align: center;
}

/** Left align lists */
.flip-card-content ul, .flip-card-content ol {
  text-align: left;
}

.flip-card-content img {
  width: 100%;
  height: 100%;
}
`

/**
 * Styled component for the front side of the flip card using @mui/material styled.
 * @param props Contains the backgroundColor and optional overridePrimaryThemeColor for the component.
 */
const FlipCardFront = styled("div")<{
  backgroundColor?: "primary"
  overridePrimaryThemeColor?: string
}>(({ theme, backgroundColor, overridePrimaryThemeColor }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  WebkitBackfaceVisibility: "hidden", // for Safari
  backfaceVisibility: "hidden",
  fontSize: "20px",
  padding: "15px",
  backgroundColor: backgroundColor
    ? lighten(overridePrimaryThemeColor ?? theme.palette.primary.main, 0.8)
    : "white",
  color: "#444",
}))

/**
 * Styled component for the back side of the flip card using @mui/material styled.
 */
const FlipCardBack = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  WebkitBackfaceVisibility: "hidden", // for Safari
  backfaceVisibility: "hidden",
  fontSize: "20px",
  padding: "15px",
  backgroundColor: "#F2F2F2",
  color: "#444",
  transform: "rotateY(180deg)",
}))

/**
 * Renders a grid of flip cards.
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.element Root element of component in light DOM.
 * @param props.data The flip card data to render.
 * @param props.onDataChange Callback to update the data.
 * @param props.withStyles Function to wrap children in styles.
 * @param props.readOnly Whether the editor is read-only.
 * @param props.overridePrimaryThemeColor Optional override for the primary theme color.
 */
export function FlipCardGridCustomComponent(props: {
  editor: any
  element: HTMLElement
  data: FlipCardGridData
  onDataChange?: (data: FlipCardGridData) => void
  withStyles: (children: React.ReactElement) => React.ReactElement
  readOnly: boolean
  overridePrimaryThemeColor?: string
}) {
  const { data, onDataChange, readOnly } = props
  const [editorOpen, setEditorOpen] = useState(false)

  return (
    <>
      <style>{flipCardStyle}</style>
      {props.withStyles(
        <Box
          sx={{
            ...audioElementStyle,
          }}
          className="flip-card-grid"
        >
          {!readOnly && (
            <div key="buttons" className="buttons">
              <IconButton
                onClick={() => setEditorOpen(true)}
                data-cy="open-interactive-elements-editor"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  props.element.remove()
                  props.editor.undo.saveStep()
                }}
                data-cy="delete-interactive-elements-editor"
              >
                <Delete />
              </IconButton>
            </div>
          )}
          <div key="cards" className="flip-card-grid-cards">
            {data.cards.map((card, index) => (
              <FlipCard
                key={index}
                card={card}
                backgroundColor={data.backgroundColor}
                overridePrimaryThemeColor={props.overridePrimaryThemeColor}
              />
            ))}
          </div>
        </Box>
      )}
      {editorOpen && (
        <FlipCardGridDialog
          initialData={data}
          onCancel={() => setEditorOpen(false)}
          onSave={(data) => {
            onDataChange!(data)
            setEditorOpen(false)
          }}
        />
      )}
    </>
  )
}

/**
 * A single flipcard component.
 * @param props See below.
 * @param props.card Card data to display.
 * @param props.backgroundColor Background color of flip card.
 * @param props.overridePrimaryThemeColor Optional override for the primary theme color.
 */
function FlipCard(props: {
  card: FlipCardData
  backgroundColor?: "primary"
  overridePrimaryThemeColor?: string
}) {
  const [flipped, setFlipped] = useState(false)

  return (
    <div
      className={flipped ? "flip-card flipped" : "flip-card"}
      onClick={(event) => {
        // Check if the event target is a hyperlink
        if ((event.target as HTMLElement).tagName === "A") {
          // Prevent the default action
          event.preventDefault()
          // Open the link in a new tab
          window.open((event.target as HTMLAnchorElement).href, "_blank")
        } else {
          setFlipped(!flipped)
        }
      }}
    >
      <div className="flip-card-inner">
        <FlipCardFront
          backgroundColor={props.backgroundColor}
          overridePrimaryThemeColor={props.overridePrimaryThemeColor}
        >
          <FlipCardFace face={props.card.front} />
        </FlipCardFront>
        <FlipCardBack>
          <FlipCardFace face={props.card.back} />
        </FlipCardBack>
      </div>
    </div>
  )
}

/**
 * A single flipcard face.
 * @param props See below.
 * @param props.face Face to display
 */
function FlipCardFace(props: { face: FlipCardFaceData }) {
  const { fontSize, ref } = useFitText()

  if (props.face.type === "image") {
    return (
      <div className="flip-card-content" ref={ref}>
        <img
          src={props.face.imageUrl}
          alt={props.face.imageAlt}
          style={{ objectFit: props.face.imageFit }}
          data-cy="flip-card-img"
        />
      </div>
    )
  } else {
    return (
      <div className="flip-card-content" ref={ref} style={{ fontSize }}>
        <div
          style={{ flexGrow: 1 }}
          dangerouslySetInnerHTML={{ __html: props.face.html }}
        />
      </div>
    )
  }
}

/**
 * Create dialog to add a flip card grid.
 * @param onClose Callback to close the dialog with the html to insert or null if cancelled.
 */
export function createAddFlipCardGridDialog(
  onClose: (html: string | null) => void
) {
  return (
    <FlipCardGridDialog
      onSave={(data) => {
        // Create a new section element
        const section = document.createElement("section")
        section.dataset.component = "flip-card-grid"
        flipCardGridCustomElement.updateElementFromData(section, data)

        onClose(section.outerHTML)
      }}
      onCancel={() => onClose(null)}
      initialData={{
        cards: [
          {
            front: {
              type: "html",
              html: "",
            },
            back: {
              type: "html",
              html: "",
            },
          },
          {
            front: {
              type: "html",
              html: "",
            },
            back: {
              type: "html",
              html: "",
            },
          },
        ],
      }}
    />
  )
}

/**
 * Dialog to edit a flip card grid.
 * @param props See below.
 * @param props.initialData Initial data to display.
 * @param props.onCancel Callback when the dialog is cancelled.
 * @param props.onSave Callback when the dialog is saved.
 */
function FlipCardGridDialog(props: {
  initialData: FlipCardGridData
  onCancel: () => void
  onSave: (data: FlipCardGridData) => void
}) {
  const { initialData, onCancel, onSave } = props

  const enableBackground = useFlag("rollout-configurable-interaction-emphasis")

  const [data, setData] = useState<FlipCardGridData>(initialData)

  return (
    <Dialog open={true} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Flip Cards</DialogTitle>
      <DialogContent>
        {enableBackground && (
          <div key="background">
            <FormControlLabel
              control={
                <Switch
                  checked={data.backgroundColor === "primary"}
                  onChange={(event) => {
                    setData(
                      produce(data, (draft) => {
                        draft.backgroundColor = event.target.checked
                          ? "primary"
                          : undefined
                      })
                    )
                  }}
                />
              }
              label="Color Contrast"
            />
          </div>
        )}
        <style>{flipCardStyle}</style>
        {data.cards.map((card, index) => (
          <div key={index} data-cy="editor-flip-card">
            <h3>
              Card #{index + 1}
              <IconButton
                sx={{ float: "right" }}
                onClick={() => {
                  setData(
                    produce(data, (draft) => {
                      draft.cards.splice(index, 1)
                    })
                  )
                }}
                data-cy="editor-delete-button"
              >
                <Delete />
              </IconButton>
            </h3>
            <div>
              <b>Front</b>
            </div>
            <FlipCardFaceComponent
              face={card.front}
              onChange={(face) => {
                setData(
                  produce(data, (draft) => {
                    draft.cards[index].front = face
                  })
                )
              }}
            />
            <br />
            <div>
              <b>Back</b>
            </div>
            <FlipCardFaceComponent
              face={card.back}
              onChange={(face) => {
                setData(
                  produce(data, (draft) => {
                    draft.cards[index].back = face
                  })
                )
              }}
            />
          </div>
        ))}

        <div key="add">
          <Button
            sx={{ mt: 1 }}
            onClick={() => {
              setData(
                produce(data, (draft) => {
                  draft.cards.push({
                    front: { type: "html", html: "" },
                    back: { type: "html", html: "" },
                  })
                })
              )
            }}
            startIcon={<Add />}
          >
            Add Flip Card
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button key="cancel" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          key="save"
          color="primary"
          variant="contained"
          onClick={() => onSave(data)}
          disabled={data.cards.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/** Props for the FlipCardFaceComponent. */
interface FlipCardFaceComponentInterface {
  /** Face to edit */
  face: FlipCardFaceData

  /** Callback when the face is changed.
   * @param face The new face data.
   */
  onChange: (face: FlipCardFaceData) => void
}

/**
 * Edits a single flip card face.
 *
 * @param props See above.
 */
function FlipCardFaceComponent(props: FlipCardFaceComponentInterface) {
  const { face, onChange } = props

  const enableNewInsertImageDialog = useFlag("rollout-new-insert-image-dialog")

  // Preserve the html when switching to image mode
  const [savedHtml, setSavedHtml] = useState(
    face.type === "html" ? face.html : undefined
  )

  // Preserve the image when switching to html mode
  const [savedImageUrl, setSavedImageUrl] = useState(
    face.type === "image" ? face.imageUrl : undefined
  )

  // Preserve the alt text when switching to html mode
  const [savedImageAlt, setSavedImageAlt] = useState(
    face.type === "image" ? face.imageAlt : undefined
  )

  // Preserve the image fit when switching to html mode
  const [savedImageFit, setSavedImageFit] = useState(
    face.type === "image" ? face.imageFit : undefined
  )

  /** Renders component to edit html */
  function renderHtmlEditor() {
    const model = (face as FlipCardFaceDataHtml).html
    /** Handle change to model
     * @param model The new model
     */
    const onModelChange = (model: string) => {
      onChange({ type: "html", html: model })
    }

    return (
      <Box sx={{ ...audioElementStyle }}>
        <FroalaEditor
          tag="textarea"
          config={minimalFroalaConfig}
          model={model}
          onModelChange={onModelChange}
        />
      </Box>
    )
  }

  /** Renders component to edit image */
  function renderImageEditor() {
    const imageFace = face as FlipCardFaceDataImage

    if (!imageFace.imageUrl && enableNewInsertImageDialog) {
      return (
        <InsertImageDialog
          onInsert={(imageUrl) => {
            if (imageUrl != null) {
              onChange({
                type: "image",
                imageUrl,
                imageFit: "scale-down",
                imageAlt: "",
              })
            }
          }}
          onCancel={() => {
            // Switch back to html mode if they cancel
            onChange({ type: "html", html: savedHtml || "" })
          }}
        />
      )
    }

    if (!imageFace.imageUrl && !enableNewInsertImageDialog) {
      return (
        <ImageUpload
          onImageUrl={(imageUrl) =>
            onChange({
              type: "image",
              imageUrl,
              imageFit: "scale-down",
              imageAlt: "",
            })
          }
        />
      )
    }
    return (
      <div>
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <FlipCardFace face={imageFace as FlipCardFaceDataImage} />
            </div>
          </div>
        </div>
        <AltTextEditor
          imageUrl={imageFace.imageUrl}
          altText={imageFace.imageAlt}
          onChange={(altText) => onChange({ ...imageFace, imageAlt: altText })}
        />
        <RadioGroup
          value={imageFace.imageFit || "scale-down"}
          row
          onChange={(e, value) => {
            if (value) {
              onChange({
                ...imageFace,
                imageFit: value as "scale-down" | "cover",
              })
            }
          }}
        >
          <FormControlLabel
            value="scale-down"
            control={<Radio />}
            label="Scale image to fit"
          />
          <FormControlLabel
            value="cover"
            control={<Radio />}
            label="Scale image to fill"
          />
        </RadioGroup>
        {enableNewInsertImageDialog ? (
          <Button
            sx={{ mt: 1 }}
            onClick={() => {
              setSavedImageUrl(undefined)
              setSavedImageAlt(undefined)
              setSavedImageFit(undefined)
              onChange({ type: "html", html: savedHtml || "" })
            }}
            startIcon={<Delete />}
          >
            Remove Image
          </Button>
        ) : (
          <Button
            sx={{ mt: 1 }}
            onClick={() => onChange({ ...imageFace, imageUrl: "" })}
            startIcon={<Delete />}
          >
            Remove Image
          </Button>
        )}
      </div>
    )
  }

  return (
    <div>
      <RadioGroup
        value={face.type}
        row
        onChange={(e, value) => {
          if (value) {
            if (value === "html") {
              setSavedImageUrl((face as FlipCardFaceDataImage).imageUrl)
              setSavedImageAlt((face as FlipCardFaceDataImage).imageAlt)
              setSavedImageFit((face as FlipCardFaceDataImage).imageFit)
              onChange({ type: "html", html: savedHtml || "" })
            }
            if (value === "image") {
              setSavedHtml((face as FlipCardFaceDataHtml).html)
              onChange({
                type: "image",
                imageUrl: savedImageUrl || "",
                imageAlt: savedImageAlt || "",
                imageFit: savedImageFit || "scale-down",
              })
            }
          }
        }}
      >
        <FormControlLabel value="html" control={<Radio />} label="Text" />
        <FormControlLabel value="image" control={<Radio />} label="Image" />
      </RadioGroup>
      {face.type === "image" && renderImageEditor()}
      {face.type === "html" && renderHtmlEditor()}
    </div>
  )
}
