import { useEffect } from "react"

import registerAudioCommands from "./registerAudioCommands"
import registerVideoCommands from "./registerVideoCommands"
import registerImageCommands from "./registerImageCommands"

/**
 * Hook for registering custom Froala commands and buttons in our editor
 *
 * @param displayReactElement - Function needed for rendering react custom components in editor
 */
const useRegisterFroalaCommands = (displayReactElement: any) => {
  /**
   * Any custom Froala commands we need to register can be placed inside this useEffect
   */
  useEffect(() => {
    registerAudioCommands(displayReactElement)
    registerVideoCommands(displayReactElement)
    registerImageCommands(displayReactElement)
  }, [displayReactElement])
}

export default useRegisterFroalaCommands
