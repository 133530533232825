import { Stack, Typography } from "@mui/material"
import ColourSelectionControl from "./ColourSelectionControl"
import ColourThemeSelectionControl from "./ColourThemeSelectionControl"
import FontSelectionControl from "./FontSelectionControl"
import ImageUploadControl from "./ImageUploadControl"
import SizeSelectionControl from "./SizeSelectionControl"
import Grid from "@mui/material/Grid"
import useFlag from "../../../../../utilities/feature-management/useFlag"
import BrandingStylesPicker from "../../../../branding/BrandingStylesPicker"
import OldImageUploadControl from "./OldImageUploadControl"

type BrandingFormProps = {
  branding: any
  onPropertyChange: (property: string, url: string | null, file: any) => void
}

/**
 * Component that displays controls for setting branding options
 * @param props - Component props
 */
const BrandingForm = (props: BrandingFormProps) => {
  const { branding, onPropertyChange } = props

  const useNewColorControls = useFlag("rollout-branding-new-color-controls")
  const enableNewInsertDialog = useFlag("rollout-new-insert-image-dialog")

  return (
    <Grid spacing={2} columnSpacing={4} container>
      <Grid item xs={12} lg={6}>
        <Stack gap={2}>
          {enableNewInsertDialog ? (
            <>
              <ImageUploadControl
                title="Logo"
                imageUrl={branding.logo}
                onChange={(imageUrl: string | null, file: any) =>
                  onPropertyChange("logo", imageUrl, file)
                }
              />
              <ImageUploadControl
                title="Brand Mark"
                imageUrl={branding.brandMark}
                onChange={(imageUrl: string | null, file: any) =>
                  onPropertyChange("brandMark", imageUrl, file)
                }
                maxWidth={24}
              />
            </>
          ) : (
            <>
              <OldImageUploadControl
                title="Logo"
                imageUrl={branding.logo}
                onChange={(imageUrl: string | null, file: any) =>
                  onPropertyChange("logo", imageUrl, file)
                }
              />
              <OldImageUploadControl
                title="Brand Mark"
                imageUrl={branding.brandMark}
                onChange={(imageUrl: string | null, file: any) =>
                  onPropertyChange("brandMark", imageUrl, file)
                }
                maxWidth={24}
              />
            </>
          )}
          {useNewColorControls ? (
            <ColourThemeSelectionControl {...props} />
          ) : (
            <ColourSelectionControl {...props} />
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack gap={2}>
          <Stack>
            <Typography gutterBottom variant="titleMedium">
              Fonts
            </Typography>
            <Stack gap={2}>
              <FontSelectionControl
                title="Heading"
                variant="heading"
                {...props}
              />
              <FontSelectionControl title="Body" variant="body" {...props} />
            </Stack>
          </Stack>
          <SizeSelectionControl {...props} />

          <BrandingStylesPicker {...props} data-cy="styles-picker" />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default BrandingForm
