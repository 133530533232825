const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
const validAudioExtensions = [
  ".mp3",
  ".wav",
  ".ogg",
  ".m4a",
  ".aac",
  ".wma",
  ".flac",
]

/**
 * For a given URL, check to make sure it is formatted correctly and points to an audio file.
 * @param input - URL to check
 */
const checkForValidAudioURL = (input: string) => {
  const url = input.trim().replace(/['"\s]/g, "")

  if (
    !urlPattern.test(url) ||
    !validAudioExtensions.some((ext) => url.toLowerCase().endsWith(ext))
  ) {
    return "Invalid audio URL. URL must end with a valid audio file extension (.mp3, .wav, .ogg, .m4a, .aac, .wma, .flac)."
  }

  // Otherwise validate the URL as valid
  return null
}

export default checkForValidAudioURL
