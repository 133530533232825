import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { Edit } from "@mui/icons-material"
import { useState } from "react"
import { InsertImageDialog } from "../../../../insertImage/InsertImageDialog"

interface ImageUploadControlProps {
  title: string
  imageUrl: string | null
  maxWidth?: number
  onChange: (imageUrl: string | null, file: any) => void
}

/**
 * Component for uploading image files and previewing them
 *
 * @param props - Component props
 * @param props.title Dialog Title
 * @param props.imageUrl Starting image
 * @param props.maxWidth Width of drop box
 * @param props.onChange Callback when changed
 */
const ImageUploadControl = ({
  title,
  imageUrl,
  maxWidth,
  onChange,
}: ImageUploadControlProps) => {
  const [showDialog, setShowDialog] = useState(false)

  return (
    <Stack flex="1 1 0">
      <Typography gutterBottom variant="titleMedium">
        {title}
      </Typography>
      <Box
        sx={(theme) => ({
          display: "flex",
          height: theme.spacing(24),
          padding: "24px",
          maxWidth: maxWidth != null ? theme.spacing(maxWidth) : "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "2px dashed",
          borderColor: theme.scheme.outlineVariant,
          color: "text.secondary",
          borderRadius: "24px",
          position: "relative",
        })}
      >
        {imageUrl ? (
          <>
            <Box
              gap={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img alt="not found" height="100rem" src={imageUrl} />
              <Button
                onClick={() => {
                  onChange(null, "null")
                }}
              >
                Remove
              </Button>
            </Box>
            <Tooltip title={"Edit image"}>
              <IconButton
                onClick={() => setShowDialog(true)}
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  right: "-10px",
                  color: "lexWhite",
                  backgroundColor: "primary.main",
                  "&:hover, &.Mui-focusVisible": {
                    backgroundColor: "primary.main",
                  },
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Button variant="contained" onClick={() => setShowDialog(true)}>
            Choose Image
          </Button>
        )}

        {showDialog && (
          <InsertImageDialog
            onInsert={(newImageUrl) => {
              setShowDialog(false)
              onChange(newImageUrl, null)
            }}
            onCancel={() => setShowDialog(false)}
          />
        )}
      </Box>
    </Stack>
  )
}

export default ImageUploadControl
