import { Box } from "@mui/material"
import { useState } from "react"
import { useDropzone } from "react-dropzone"
import { uploadFroalaImage } from "../api"

/** Upload an image to the server, returning a url
 * @param props Props
 * @param props.onImageUrl Callback to return the image url
 * @param props.onUploadingChange Optional callback for uploading state changes
 */
export function ImageUpload(props: {
  onImageUrl: (imageUrl: string) => void
  onUploadingChange?: (uploading: boolean) => void
}) {
  const { onImageUrl, onUploadingChange } = props

  const [uploading, setUploading] = useState(false)

  /**
   * Change uploading status
   * @param value new uploading value
   */
  const updateUploading = (value: boolean) => {
    setUploading(value)
    onUploadingChange?.(value)
  }

  /** Handle a drop event
   * @param acceptedFiles Files that were dropped
   */
  async function onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length === 0) return

    updateUploading(true)
    try {
      const imageUrl = await uploadFroalaImage(acceptedFiles[0])
      onImageUrl(imageUrl)
    } finally {
      updateUploading(false)
    }
  }

  // Set up dropzone
  const dropzone = useDropzone({
    multiple: false,
    accept: {
      // Only allow images
      "image/*": [".png", ".jpg", ".jpeg", ".gif"],
    },
    onDrop,
    disabled: uploading,
  })

  return (
    <Box
      {...dropzone.getRootProps()}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "2px dashed",
        borderColor: "neutral.main",
        color: "text.secondary",
        borderRadius: 1,
        py: 4,
        px: 2,
        gap: 4,
        marginBottom: 4,
      }}
    >
      <input {...dropzone.getInputProps()} />
      {dropzone.isDragActive ? (
        <p>Drop the image here</p>
      ) : (
        <p>Drag and drop the image here, or click to select file</p>
      )}
    </Box>
  )
}
