import ImageEditor from "@learnexperts/tui-image-editor"
import config from "../../../config"

import "@learnexperts/tui-image-editor/dist/tui-image-editor.min.css"

const UPLOAD_URL = `${config.apiUrl}/files/froala`

const pluginsEnabled = [
  "align",
  "audio",
  "charCounter",
  "codeBeautifier",
  "codeView",
  "colors",
  "draggable",
  "embedly",
  "emoticons",
  "entities",
  "file",
  "fontAwesome",
  "fontFamily",
  "fontSize",
  "fullscreen",
  "help",
  "image",
  "imageManager",
  "inlineClass",
  "inlineStyle",
  "lineBreaker",
  "lineHeight",
  "link",
  "lists",
  "paragraphFormat",
  "paragraphStyle",
  "print",
  "quote",
  "save",
  "specialCharacters",
  "table",
  "track_changes",
  "url",
  "video",
  "wordPaste",
]

const editorConfig = {
  key: config.froalaLicense,
  pluginsEnabled,
  height: 100,
  //Set the editor zIndex:
  //   - below the app bar
  //   - above the desktop drawer
  //   - below the mobile drawer (which is above everything)
  //https://mui.com/customization/z-index/
  zIndex: 1200,
  toolbarBottom: false,
  charCounterCount: false,
  attribution: false,
  videoUpload: false,
  htmlUntouched: true,
  htmlAllowedAttrs: [
    "accept",
    "accept-charset",
    "accesskey",
    "action",
    "align",
    "allowfullscreen",
    "allowtransparency",
    "alt",
    "aria-.*",
    "async",
    "autocomplete",
    "autofocus",
    "autoplay",
    "autosave",
    "background",
    "bgcolor",
    "border",
    "charset",
    "cellpadding",
    "cellspacing",
    "checked",
    "cite",
    "class",
    "color",
    "cols",
    "colspan",
    "content",
    "contenteditable",
    "contextmenu",
    "controls",
    "coords",
    "crossorigin",
    "data",
    "data-.*",
    "datetime",
    "default",
    "defer",
    "dir",
    "dirname",
    "disabled",
    "download",
    "draggable",
    "dropzone",
    "enctype",
    "for",
    "form",
    "formaction",
    "frameborder",
    "headers",
    "height",
    "hidden",
    "high",
    "href",
    "hreflang",
    "http-equiv",
    "icon",
    "id",
    "ismap",
    "itemprop",
    "keytype",
    "kind",
    "label",
    "lang",
    "language",
    "list",
    "loop",
    "low",
    "max",
    "maxlength",
    "media",
    "method",
    "min",
    "mozallowfullscreen",
    "multiple",
    "muted",
    "name",
    "novalidate",
    "open",
    "optimum",
    "pattern",
    "ping",
    "placeholder",
    "playsinline",
    "poster",
    "preload",
    "pubdate",
    "radiogroup",
    "readonly",
    "rel",
    "required",
    "reversed",
    "rows",
    "rowspan",
    "sandbox",
    "scope",
    "scoped",
    "scrolling",
    "seamless",
    "selected",
    "shape",
    "size",
    "sizes",
    "span",
    "src",
    "srcdoc",
    "srclang",
    "srcset",
    "start",
    "step",
    "summary",
    "spellcheck",
    "style",
    "tabindex",
    "target",
    "title",
    "type",
    "translate",
    "usemap",
    "value",
    "valign",
    "webkitallowfullscreen",
    "width",
    "wrap",
  ],
  paragraphFormatSelection: true,
  paragraphDefaultSelection: "Paragraph",
  fontFamilySelection: true,
  fontFamilyDefaultSelection: "Default",
  // By manually defining the font families in the dropdown, we can provide an "Default" font option which defaults to Froala sans-serif base font.
  fontFamily: {
    "": "Default",
    "Arial,Helvetica,sans-serif": "Arial",
    "Georgia, serif": "Georgia",
    "Impact, Charcoal, sans-serif": "Impact",
    "Tahoma,Geneva,sans-serif": "Tahoma",
    "Times New Roman, Times, serif": "Times New Roman",
    "Verdana, Geneva, sans-serif": "Verdana",
  },
  placeholderText: "",
  events: {
    /**
     * Do nothing on button refresh
     */
    "buttons.refresh": () => {},
  },
  // Exclude &quot; &#39; &lang; &rang; to match LearnExperts standard
  entities:
    "&iexcl;&cent;&pound;&curren;&yen;&brvbar;&sect;&uml;&copy;&ordf;&laquo;&not;&shy;&reg;&macr;&deg;&plusmn;&sup2;&sup3;&acute;&micro;&para;&middot;&cedil;&sup1;&ordm;&raquo;&frac14;&frac12;&frac34;&iquest;&Agrave;&Aacute;&Acirc;&Atilde;&Auml;&Aring;&AElig;&Ccedil;&Egrave;&Eacute;&Ecirc;&Euml;&Igrave;&Iacute;&Icirc;&Iuml;&ETH;&Ntilde;&Ograve;&Oacute;&Ocirc;&Otilde;&Ouml;&times;&Oslash;&Ugrave;&Uacute;&Ucirc;&Uuml;&Yacute;&THORN;&szlig;&agrave;&aacute;&acirc;&atilde;&auml;&aring;&aelig;&ccedil;&egrave;&eacute;&ecirc;&euml;&igrave;&iacute;&icirc;&iuml;&eth;&ntilde;&ograve;&oacute;&ocirc;&otilde;&ouml;&divide;&oslash;&ugrave;&uacute;&ucirc;&uuml;&yacute;&thorn;&yuml;&OElig;&oelig;&Scaron;&scaron;&Yuml;&fnof;&circ;&tilde;&Alpha;&Beta;&Gamma;&Delta;&Epsilon;&Zeta;&Eta;&Theta;&Iota;&Kappa;&Lambda;&Mu;&Nu;&Xi;&Omicron;&Pi;&Rho;&Sigma;&Tau;&Upsilon;&Phi;&Chi;&Psi;&Omega;&alpha;&beta;&gamma;&delta;&epsilon;&zeta;&eta;&theta;&iota;&kappa;&lambda;&mu;&nu;&xi;&omicron;&pi;&rho;&sigmaf;&sigma;&tau;&upsilon;&phi;&chi;&psi;&omega;&thetasym;&upsih;&piv;&ensp;&emsp;&thinsp;&zwnj;&zwj;&lrm;&rlm;&ndash;&mdash;&lsquo;&rsquo;&sbquo;&ldquo;&rdquo;&bdquo;&dagger;&Dagger;&bull;&hellip;&permil;&prime;&Prime;&lsaquo;&rsaquo;&oline;&frasl;&euro;&image;&weierp;&real;&trade;&alefsym;&larr;&uarr;&rarr;&darr;&harr;&crarr;&lArr;&uArr;&rArr;&dArr;&hArr;&forall;&part;&exist;&empty;&nabla;&isin;&notin;&ni;&prod;&sum;&minus;&lowast;&radic;&prop;&infin;&ang;&and;&or;&cap;&cup;&int;&there4;&sim;&cong;&asymp;&ne;&equiv;&le;&ge;&sub;&sup;&nsub;&sube;&supe;&oplus;&otimes;&perp;&sdot;&lceil;&rceil;&lfloor;&rfloor;&loz;&spades;&clubs;&hearts;&diams;",
  shortcutsEnabled: [
    "show",
    "bold",
    "italic",
    "underline",
    "indent",
    "outdent",
    "undo",
    "redo",
    "insertImage",
    "createLink",
  ],
  lineHeights: {
    Default: "",
    Single: "1",
    1.15: "1.15",
    1.5: "1.5",
  },
  toolbarButtons: {
    moreText: {
      buttons: [
        "undo",
        "redo",
        "paragraphFormat",
        "fontFamily",
        "fontSize",
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "textColor",
        "backgroundColor",
        "clearFormatting",
      ],
      buttonsVisible: 7,
    },
    moreParagraph: {
      buttons: [
        "formatOL",
        "formatUL",

        "alignLeft",
        "alignCenter",
        "alignRight",
        "alignJustify",
        "lineHeight",
        "outdent",
        "indent",
        "quote",
      ],
      buttonsVisible: 2,
    },
    moreRich: {
      buttons: [
        "insertLink",
        "insertImage",
        "showVideoDialog",
        "insertTable",
        "emoticons",
        "specialCharacters",
        "insertHR",
      ],
      buttonsVisible: 4,
    },
    fullscreen: {
      buttons: ["fullscreen"],
      align: "right",
    },
    moreMisc: {
      buttons: ["print", "selectAll", "help"],
      align: "right",
      buttonsVisible: 0,
    },
  },
  paragraphFormat: {
    N: "Normal",
    H1: "Heading 1",
    H2: "Heading 2",
    H3: "Heading 3",
    H4: "Heading 4",
    H5: "Heading 5",
    H6: "Heading 6",
    PRE: "Code Block",
  },
  tableInsertHelper: false,
  imageUploadURL: UPLOAD_URL,
  fileUploadURL: UPLOAD_URL,
  videoUploadURL: UPLOAD_URL,
  requestWithCredentials: true,
  imageTUIOptions: {
    includeUI: {
      theme: {
        "common.bisize.width": "0px",
        "Common.bisize.height": "0px",
        "common.backgroundImage": "./img/bg.png",
        "common.backgroundColor": "#fff",
        "common.border": "1px solid #c1c1c1",
      },
      initMenu: "crop",
      menuBarPosition: "right",
    },
  },
  tui: { ImageEditor },
  // Display imageAutoAlt which is our special button to modify the alt text
  imageEditButtons: [
    "imageReplace",
    "imageAlign",
    "imageCaption",
    "imageRemove",
    "imageLink",
    "linkOpen",
    "linkEdit",
    "linkRemove",
    "-",
    "imageDisplay",
    "imageStyle",
    "imageAutoAlt", // Special button to replace "imageAlt"
    "imageSize",
    "imageTUI",
  ],
  tableResizerOffset: 10,
  tableStyles: {
    "fr-dashed-borders": "Dashed Borders",
    "fr-alternate-rows": "Alternate Rows",
    "no-borders": "No Borders",
    centered: "Centered Table",
  },
}

export default editorConfig
