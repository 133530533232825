import { selectAfterText } from "../../utilities/domUtils"
import createAudioHTML from "./createAudioHTML"
import { AudioData, createInsertAudioDialog } from "./InsertAudioDialog"

const initialData: AudioData = {
  title: "",
  url: "",
}

/**
 * Callback to insert audio into HTML when dialog is closed
 * @param anchorElement - Location in editor
 * @param onClose - Callback to close dialog
 * @param editor - Froala editor instance
 */
const onCreateAudio = (anchorElement: any, onClose: () => void, editor: any) =>
  createInsertAudioDialog((data) => {
    if (data) {
      const { url, title } = data

      const html = createAudioHTML(title, url)
      console.log(html)
      // Add a paragraph after the image so the user can type after it
      anchorElement.innerHTML = "<br/>"
      anchorElement.insertAdjacentHTML("beforebegin", html)
      editor.undo.saveStep()
      selectAfterText(anchorElement, editor)
    }

    onClose()
  }, initialData)

export default onCreateAudio
