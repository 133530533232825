import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  LinearProgress,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import Input from "@mui/material/Input"
import Chip from "@mui/material/Chip"
import { without } from "lodash/fp"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"
import TextField from "@mui/material/TextField"
import CopyToClipboardField from "../../components/atoms/CopyToClipboardField"
import Divider from "@mui/material/Divider"
import LearnerLinkDialog from "../../components/screens/LearnerLinkDialog"
import React from "react"
import {
  AUDIENCE_OPTIONS,
  MAX_REGISTRATION_CAP,
  MODALITY_OPTIONS,
  SKILL_LEVEL_OPTIONS,
} from "./utils/contants"
import useCourseExport from "./hooks/useCourseExport"
import BrandingForm from "../branding/BrandingForm"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import ExportErrorMessage from "./ExportErrorMessage"
import { useFlag } from "../../utilities/feature-management"
import { isEmpty } from "lodash"
import ImageUploadBox from "../branding/ImageUploadBox"
import PreviewIcon from "@mui/icons-material/Preview"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import LmsExportDialog from "../../components/screens/LmsExportDialog"
import AccessibilityWarning from "../../components/atoms/AccessibilityWarning"
import useExportTabs from "./hooks/useExportTabs"
import { tabValueEnum } from "./hooks/useExportTabs"
import { minimalFroalaConfig } from "../../custom-elements/minimalFroalaConfig"
import FroalaEditor from "react-froala-wysiwyg"
import { TranslationControls } from "./TranslationControls"
import {
  ButtonEvents,
  useMixpanelButtonTracker,
} from "../../utilities/mixpanel"
import LmsExportReplaceDialog from "../../components/screens/LmsExportReplaceDialog"
const { EXPORT_SETTINGS, ASSET_SETTINGS, BRANDING } = tabValueEnum

const FormControlRoot = styled(FormControl, { shouldForwardProp: isPropValid })(
  () => ({
    marginTop: 16,
    width: "100%",
  })
)

const ChipsWrapper = styled(Box, { shouldForwardProp: isPropValid })(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: 0.5,
}))

const ChipRoot = styled(Chip, { shouldForwardProp: isPropValid })(() => ({
  margin: 2,
}))

/**
 * UI for exporting course
 */
const ExportScreen = () => {
  const {
    courseId,
    asyncJobError,
    onExit,
    isLoading,
    asyncJobStatus,
    modalities,
    handleChangeModalities,
    setModalities,
    audiences,
    handleChangeAudiences,
    setAudiences,
    skillLevel,
    handleChangeSkillLevel,
    highLevelGoal,
    handleChangeHighLevelGoal,
    prerequisites,
    handleChangePrerequisites,
    setPrerequisites,
    preRequisiteCourseList,
    format,
    handleChangeFormat,
    formatOptions,
    passingScore,
    handleChangePassingScore,
    showCorrectAnswer,
    handleChangeShowCorrectAnswer,
    animateListItems,
    handleChangeAnimateListItems,
    courseExportTitle,
    handleChangeCourseExportTitle,
    scrollAnimation,
    handleChangeScrollAnimation,
    restrictNavigation,
    handleChangeRestrictNavigation,
    mustPassQuizzes,
    handleChangeMustPassQuizzes,
    scormSeparateSections,
    handleChangeScormSeparateSections,
    scormExcludeCourseNavigation,
    handleChangeScormExcludeCourseNavigation,
    scormDisableAccessibleControls,
    handleChangeScormDisableAccessibleControls,
    scormEnableImageZoom,
    handleChangeScormEnableImageZoom,
    scormHideSectionHeadings,
    handleChangeScormHideSectionHeadings,
    scormHideDuration,
    handleChangeScormHideDuration,
    scormExcludeSummarySection,
    handleChangeScormExcludeSummarySection,
    scormSubSectionNavigation,
    handleChangeScormSubSectionNavigation,
    excludeExercises,
    handleChangeExcludeExercises,
    excludeDemonstrations,
    handleChangeExcludeDemonstrations,
    excludeQuizzes,
    handleChangeExcludeQuizzes,
    addSlideNumbers,
    handleChangeAddSlideNumbers,
    startPageGraphic,
    handleChangeStartPageGraphicFile,
    bookCoverGraphic,
    handleChangeBookCoverGraphicFile,
    separateDemonstrationsAndExcercises,
    handleChangeSeparateDemonstrationsAndExcercises,
    registrationCap,
    handleChangeRegistrationCap,
    learnerUrl,
    handleExportAssets,
    courseTitle,
    summaryHtml,
    setSummaryHtml,
    showLearnerLink,
    setShowLearnerLink,
    showLmsExportLink,
    showLmsDeleteWarning,
    setShowLmsExportLink,
    showLmsReplaceDialog,
    setShowLmsReplaceDialog,
    publishedCourse,
    handleReplaceSkillJar,
    lmsExportLink,
    branding,
    onPropertyChange,
    tone,
    handleChangeTone,
    overrides,
    restoreDefaultBranding,
    googleFonts,
    recentFonts,
    updateRecentFonts,
    canPreview,
    previewModalElement,
    handleShowPreview,
    inaccessibleElements,
    noExportMessage,
    canExport,
    saveSummaryHTML,
    courseHtml,
    translationLanguageCode,
    handleChangeTranslationLanguageCode,
  } = useCourseExport()

  const { availableTabs, tabValue, setTabValue } = useExportTabs(format)

  const rolloutScrollAnimation = useFlag("rollout-scroll-animation-in-export")
  const rolloutRestrictNavigation = useFlag(
    "rollout-restrict-navigation-in-export"
  )
  const rolloutMustPassQuizzes = useFlag("rollout-must-pass-quizzes-in-export")
  const rolloutExcludeSectionsFromPptxExport = useFlag(
    "rollout-exclude-sections-from-pptx-export"
  )
  const rolloutStartPageGraphic = useFlag(
    "rollout-start-page-graphic-in-export"
  )
  const rolloutExportTitle = useFlag("rollout-course-title-in-export")

  const rolloutScormPreview = useFlag("rollout-scorm-preview")

  const rolloutScormSeparateSections = useFlag(
    "rollout-scorm-separate-sections"
  )

  const rolloutScormHideSectionHeadings = useFlag(
    "rollout-scorm-hide-section-headings"
  )
  const rolloutScormEnableImageZoom = useFlag("rollout-scorm-enable-image-zoom")

  const rolloutScormDisableAccessibleControls = useFlag(
    "rollout-scorm-disable-accessible-controls"
  )

  const rolloutScormHideDuration = useFlag("rollout-scorm-hide-duration")

  const rolloutScormExcludeSummarySection = useFlag(
    "rollout-scorm-exclude-summary-section"
  )

  const rolloutScormExcludeNavigation = useFlag(
    "rollout-scorm-exclude-navigation"
  )

  const rolloutScormSubSectionNavigation = useFlag(
    "rollout-scorm-subsection-navigation"
  )

  const rolloutScormIntroSummary = useFlag("rollout-scorm-intro-summary")

  const rolloutSlideNumbering = useFlag("rollout-slide-numbering")

  const rolloutTranslation = useFlag("rollout-translate-courses-on-export")

  const useNewSettingsLayout = useFlag("rollout-new-export-settings-layout")

  const trackPreviewButtonClick = useMixpanelButtonTracker(
    ButtonEvents.PreviewCourse
  )
  const trackExportButtonClick = useMixpanelButtonTracker(
    ButtonEvents.ExportCourse
  )

  // Display warning if couse has any inaccessible elements and the export is to LMS
  const showAccessibilityWarning =
    inaccessibleElements.length > 0 &&
    (format.startsWith("lms") || format.startsWith("scorm"))

  const AssetSettingsOptions = (
    <>
      {format !== "video-script" && (
        <FormControlRoot>
          <InputLabel id="course-format-chip-label">Course Format</InputLabel>
          <Select
            data-cy="export-course-format-selector"
            labelId="course-format-chip-label"
            id="course-format-multiple-chip"
            multiple
            value={modalities}
            onChange={handleChangeModalities}
            input={<Input id="select-multiple-modalities" />}
            renderValue={(selected) => (
              <ChipsWrapper>
                {selected.map((value) => (
                  <ChipRoot
                    key={value}
                    label={value}
                    onDelete={() => setModalities(without([value]))}
                    onMouseDown={(e) => {
                      e.stopPropagation()
                    }}
                  />
                ))}
              </ChipsWrapper>
            )}
          >
            {MODALITY_OPTIONS.map((name: string) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            The instructional delivery method available for the course.
          </FormHelperText>
        </FormControlRoot>
      )}
      {format !== "video-script" && (
        <FormControlRoot>
          <InputLabel id="audience-multiple-chip-label">Audience</InputLabel>
          <Select
            data-cy="export-course-audience-selector"
            labelId="audience-multiple-chip-label"
            id="audience-multiple-chip"
            multiple
            value={audiences}
            onChange={handleChangeAudiences}
            input={<Input id="select-multiple-audience" />}
            renderValue={(selected) => (
              <ChipsWrapper>
                {selected.map((value: any) => (
                  <ChipRoot
                    key={value}
                    label={value}
                    onDelete={() => setAudiences(without([value]))}
                    onMouseDown={(e) => {
                      e.stopPropagation()
                    }}
                  />
                ))}
              </ChipsWrapper>
            )}
          >
            {AUDIENCE_OPTIONS.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            The group of people the course is intended for, may be multiple
            groups.
          </FormHelperText>
        </FormControlRoot>
      )}
      {format !== "video-script" && (
        <FormControlRoot>
          <InputLabel id="skill-level-select-helper-label">
            Skill Level
          </InputLabel>
          <Select
            data-cy="export-course-skill-level-selector"
            labelId="skill-level-simple-select-helper-label"
            id="skill-level-simple-select-helper"
            value={skillLevel}
            onChange={handleChangeSkillLevel}
            input={<Input id="select-skill-level" />}
          >
            <MenuItem value="">&nbsp;</MenuItem>
            {SKILL_LEVEL_OPTIONS.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>The type or complexity of the course.</FormHelperText>
        </FormControlRoot>
      )}
      {format !== "video-script" && (
        <FormControlRoot>
          <TextField
            data-cy="export-course-txt-high-level-goal-textfield"
            id="txt-high-level-goal"
            label="The high level goal(s) of this course is/are to"
            value={highLevelGoal}
            onChange={handleChangeHighLevelGoal}
          />
          <FormHelperText>
            The main skill or knowledge that participants will learn once they
            have completed the course.
          </FormHelperText>
        </FormControlRoot>
      )}
      {format !== "video-script" && (
        <FormControlRoot>
          <InputLabel id="prerequisites-multiple-chip-label">
            Prerequisites
          </InputLabel>
          <Select
            data-cy="export-course-prerequ-selector"
            labelId="prerequisites-multiple-chip-label"
            id="prerequisites-multiple-chip"
            multiple
            value={prerequisites}
            onChange={handleChangePrerequisites}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <ChipsWrapper>
                {selected.map((value: any) => (
                  <ChipRoot
                    key={value}
                    label={value}
                    onDelete={() => setPrerequisites(without([value]))}
                    onMouseDown={(e) => {
                      e.stopPropagation()
                    }}
                  />
                ))}
              </ChipsWrapper>
            )}
          >
            {preRequisiteCourseList.map((courseItem: any) => (
              <MenuItem key={courseItem} value={courseItem}>
                {courseItem}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            A course or requirement that a participant must take prior to
            enrolling in course or program.
          </FormHelperText>
        </FormControlRoot>
      )}
    </>
  )

  return (
    <Container fixed maxWidth="md" sx={{ height: "100vh" }}>
      {previewModalElement}
      {asyncJobError ? (
        <ExportErrorMessage onExit={onExit} />
      ) : (
        <Box display="flex" flexDirection="column" height="100%">
          <Box marginTop={6}>
            <Typography variant="h5" component="h1">
              Export Course
            </Typography>
            <Typography
              variant="body1"
              align="justify"
              sx={{ marginBottom: 2 }}
            >
              Select your export format and / or type below, then enter the
              additional information required to complete your export.
            </Typography>
            {isLoading ? <LinearProgress color="primary" /> : ""}
            <Typography variant="caption" align="right" display="block">
              {asyncJobStatus ? asyncJobStatus : ""}
            </Typography>
            <FormControlRoot>
              <InputLabel id="format-select-helper-label">
                Export Format
              </InputLabel>
              <Select
                labelId="format-simple-select-helper-label"
                id="format-simple-select-helper"
                value={
                  formatOptions.find((opt) => opt.value === format) == null
                    ? formatOptions[0].value
                    : format
                }
                onChange={handleChangeFormat}
                input={<Input id="select-format" />}
              >
                {formatOptions.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                <span>
                  Create the export as Word (.docx), PowerPoint (.pptx), SCORM,
                  HTML, or Video Script (.docx).
                </span>
              </FormHelperText>
            </FormControlRoot>
          </Box>

          <Box
            marginTop={2}
            marginBottom={1}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tabs
              data-cy="export-tabs-settings"
              value={tabValue}
              onChange={(event: React.SyntheticEvent, newValue: number) =>
                setTabValue(newValue)
              }
              variant="fullWidth"
            >
              {availableTabs.map(({ label, value }) => (
                <Tab label={label} value={value} />
              ))}
            </Tabs>
          </Box>
          <Box sx={{ flex: 2, overflow: "auto", pr: 1 }}>
            {tabValue === EXPORT_SETTINGS && (
              <form noValidate autoComplete="off">
                {format === "video-script" && (
                  <FormControlRoot>
                    <InputLabel id="format-select-helper-label">
                      Tone
                    </InputLabel>
                    <Select
                      labelId="format-simple-select-helper-label"
                      id="format-simple-select-helper"
                      value={tone}
                      onChange={handleChangeTone}
                      input={<Input id="select-format" />}
                    >
                      <MenuItem key="professional" value="professional">
                        Professional
                      </MenuItem>
                      <MenuItem key="informal" value="informal">
                        Informal
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      <span>Select the tone of the video script.</span>
                    </FormHelperText>
                  </FormControlRoot>
                )}
                {(format.startsWith("lms") ||
                  format.startsWith("scorm") ||
                  format === "epub") && (
                  <>
                    {rolloutExportTitle && (
                      <FormControlRoot>
                        <TextField
                          data-cy="export-course-title-textfield"
                          id="export-scorm-title"
                          label="Course Title"
                          value={courseExportTitle}
                          onChange={handleChangeCourseExportTitle}
                        />
                        <FormHelperText>Title for the course.</FormHelperText>
                      </FormControlRoot>
                    )}
                  </>
                )}
                {format === "epub" && (
                  <FormControlRoot>
                    <ImageUploadBox
                      title="Book Cover Image"
                      imageUrl={bookCoverGraphic}
                      onChange={handleChangeBookCoverGraphicFile}
                      maxWidth={16}
                    />
                    <FormHelperText>
                      Image for epub book cover. Suggested size is 400 by 600
                      pixels.
                    </FormHelperText>
                  </FormControlRoot>
                )}
                {(format.startsWith("lms") || format.startsWith("scorm")) && (
                  <>
                    {rolloutStartPageGraphic && (
                      <FormControlRoot id="export-course-image-drop-box">
                        <ImageUploadBox
                          data-cy="export-course-image-drop-box-two"
                          title="Course Thumbnail (displays in upper left corner)"
                          imageUrl={startPageGraphic}
                          onChange={handleChangeStartPageGraphicFile}
                          maxWidth={40}
                        />
                        <FormHelperText>
                          Best image size is 360 x 180 pixels.
                        </FormHelperText>
                        <Box mb={4}></Box>
                      </FormControlRoot>
                    )}
                    <FormControlRoot>
                      <TextField
                        data-cy="export-course-total-passing-score"
                        inputProps={{ inputMode: "numeric", pattern: "\\d*" }}
                        id="passing-score"
                        label="Total Passing Score"
                        value={passingScore}
                        onChange={handleChangePassingScore}
                      />
                      <FormHelperText>
                        Total passing score for all course test questions as a
                        percentage.
                      </FormHelperText>
                    </FormControlRoot>
                    <FormControlRoot>
                      <FormControlLabel
                        control={
                          <Switch
                            data-cy="export-course-display-correct-test-answers"
                            id="show-correct-answer"
                            checked={showCorrectAnswer}
                            onChange={handleChangeShowCorrectAnswer}
                          />
                        }
                        label="Display Correct Test Answers"
                      />
                      <FormHelperText>
                        Show users the correct answers on incorrect test
                        questions.
                      </FormHelperText>
                    </FormControlRoot>
                    {rolloutScormExcludeNavigation && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              data-cy="export-course-exclude-navigation"
                              id="scorm-optional-player"
                              checked={scormExcludeCourseNavigation}
                              onChange={
                                handleChangeScormExcludeCourseNavigation
                              }
                            />
                          }
                          label="Exclude Course Navigation"
                        />
                        <FormHelperText>
                          Exclude navigation UI from SCORM package.
                        </FormHelperText>
                      </FormControlRoot>
                    )}
                    {rolloutRestrictNavigation && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              data-cy="export-course-restrict-navigation"
                              id="restrict-navigation"
                              disabled={scormExcludeCourseNavigation}
                              checked={restrictNavigation}
                              onChange={handleChangeRestrictNavigation}
                            />
                          }
                          label="Restrict Navigation"
                        />
                        <FormHelperText>
                          Learner's must complete a section before progressing
                          to the next section.
                        </FormHelperText>
                      </FormControlRoot>
                    )}
                    {rolloutMustPassQuizzes && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              data-cy="export-course-must-pass"
                              id="must-pass-quizzes"
                              disabled={scormExcludeCourseNavigation}
                              checked={mustPassQuizzes}
                              onChange={handleChangeMustPassQuizzes}
                            />
                          }
                          label="Must Pass Quizzes"
                        />
                        <FormHelperText>
                          A passing mark must be acheived before continuing to
                          sections after a quiz.
                        </FormHelperText>
                      </FormControlRoot>
                    )}
                    {rolloutScormSubSectionNavigation && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              data-cy="export-course-sub-section"
                              id="sub-section-navigation"
                              disabled={scormExcludeCourseNavigation}
                              checked={scormSubSectionNavigation}
                              onChange={handleChangeScormSubSectionNavigation}
                            />
                          }
                          label="Include Sub-Sections in Course Navigation"
                        />
                        <FormHelperText>
                          Include subsections in SCORM navigation UI.
                        </FormHelperText>
                      </FormControlRoot>
                    )}
                    {rolloutScormSeparateSections &&
                      format !== "lms-scormcloud" && (
                        <FormControlRoot>
                          <FormControlLabel
                            control={
                              <Switch
                                data-cy="export-course-seperate-lessons"
                                id="scorm-separate-sections"
                                checked={scormSeparateSections}
                                onChange={handleChangeScormSeparateSections}
                              />
                            }
                            label="Separate Lessons"
                          />
                          <FormHelperText>
                            Create a SCORM package for each lesson of the
                            course. Preview is not avaialble when this is
                            selected.
                          </FormHelperText>
                        </FormControlRoot>
                      )}
                    {rolloutScormHideSectionHeadings && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              id="scorm-hide-section-headings"
                              checked={scormHideSectionHeadings}
                              onChange={handleChangeScormHideSectionHeadings}
                            />
                          }
                          label="Hide Lesson Headings"
                        />
                        <FormHelperText>
                          Hide lesson headings. Useful when your LMS shows the
                          headings too.
                        </FormHelperText>
                      </FormControlRoot>
                    )}
                    {rolloutScormEnableImageZoom && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              id="scorm-enable-image-zoom"
                              checked={scormEnableImageZoom}
                              onChange={handleChangeScormEnableImageZoom}
                            />
                          }
                          label="Enable Image Zoom"
                        />
                        <FormHelperText>
                          Add controls to view high resolution images at full
                          size.
                        </FormHelperText>
                      </FormControlRoot>
                    )}
                    {rolloutScormDisableAccessibleControls && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              id="scorm-disable-accessible-controls"
                              checked={scormDisableAccessibleControls}
                              onChange={
                                handleChangeScormDisableAccessibleControls
                              }
                            />
                          }
                          label="Disable Accessible Controls"
                        />
                        <FormHelperText>
                          Disable accessible controls for Flip Cards.
                        </FormHelperText>
                      </FormControlRoot>
                    )}

                    {rolloutScormHideDuration && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              id="scorm-hide-duration"
                              checked={scormHideDuration}
                              onChange={handleChangeScormHideDuration}
                            />
                          }
                          label="Hide Duration"
                        />
                        <FormHelperText>
                          Hide the course duration in the SCORM left menu.
                        </FormHelperText>
                      </FormControlRoot>
                    )}
                    {rolloutScormExcludeSummarySection && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              id="scorm-exclude-summary-section"
                              checked={scormExcludeSummarySection}
                              onChange={handleChangeScormExcludeSummarySection}
                            />
                          }
                          label="Exclude Summary Section"
                        />
                        <FormHelperText>
                          Exclude summary section. The summary section show the
                          learner's course progress and score.
                        </FormHelperText>
                      </FormControlRoot>
                    )}
                    {rolloutScrollAnimation && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              data-cy="export-course-animate-content"
                              id="animate-content"
                              checked={scrollAnimation}
                              onChange={handleChangeScrollAnimation}
                            />
                          }
                          label="Animate content"
                        />
                        <FormHelperText>
                          Fade content in as it is scrolled into view.
                        </FormHelperText>
                      </FormControlRoot>
                    )}
                  </>
                )}
                {(format.startsWith("lms") ||
                  format.startsWith("scorm") ||
                  format === "pptx") && (
                  <>
                    <FormControlRoot>
                      <FormControlLabel
                        control={
                          <Switch
                            data-cy="export-course-animate-list-items"
                            id="animate-list-items"
                            checked={animateListItems}
                            onChange={handleChangeAnimateListItems}
                          />
                        }
                        label="Animate List Items"
                      />
                      <FormHelperText>
                        Slide list items in from the right.
                      </FormHelperText>
                    </FormControlRoot>
                  </>
                )}
                {format === "epub" && (
                  <FormControlRoot>
                    <FormControlLabel
                      control={
                        <Switch
                          id="separate-demonstrations-and-excercisesr"
                          checked={separateDemonstrationsAndExcercises}
                          onChange={
                            handleChangeSeparateDemonstrationsAndExcercises
                          }
                        />
                      }
                      label="Separate Demonstrations/Exercises"
                    />
                    <FormHelperText>
                      Place demonstrations and exercises in their own separate
                      sections.
                    </FormHelperText>
                  </FormControlRoot>
                )}

                {format === "pptx" && rolloutExcludeSectionsFromPptxExport && (
                  <>
                    <FormControlRoot>
                      <FormControlLabel
                        control={
                          <Switch
                            id="exclude-demonstrations"
                            checked={excludeDemonstrations}
                            onChange={handleChangeExcludeDemonstrations}
                          />
                        }
                        label="Exclude Demonstrations"
                      />
                      <FormHelperText>
                        Do not create slides for demonstration sections.
                      </FormHelperText>
                    </FormControlRoot>
                    <FormControlRoot>
                      <FormControlLabel
                        control={
                          <Switch
                            id="exclude-exercises"
                            checked={excludeExercises}
                            onChange={handleChangeExcludeExercises}
                          />
                        }
                        label="Exclude Exercises"
                      />
                      <FormHelperText>
                        Do not create slides for exercise sections.
                      </FormHelperText>
                    </FormControlRoot>
                    <FormControlRoot>
                      <FormControlLabel
                        control={
                          <Switch
                            id="exclude-quizzes"
                            checked={excludeQuizzes}
                            onChange={handleChangeExcludeQuizzes}
                          />
                        }
                        label="Exclude Quizzes"
                      />
                      <FormHelperText>
                        Do not create slides for quiz sections.
                      </FormHelperText>
                    </FormControlRoot>
                    {rolloutSlideNumbering && (
                      <FormControlRoot>
                        <FormControlLabel
                          control={
                            <Switch
                              id="slide-numbers"
                              checked={addSlideNumbers}
                              onChange={handleChangeAddSlideNumbers}
                            />
                          }
                          label="Slide Numbers"
                        />
                        <FormHelperText>
                          Add slide numbers to the exported PowerPoint.
                        </FormHelperText>
                      </FormControlRoot>
                    )}
                  </>
                )}
                {format === "lms-scormcloud" && (
                  <FormControlRoot>
                    <TextField
                      inputProps={{ inputMode: "numeric", pattern: "\\d*" }}
                      id="registration-cap"
                      label="Registration Cap"
                      value={registrationCap}
                      onChange={handleChangeRegistrationCap}
                    />
                    <FormHelperText>
                      Maximum number of learners that can register for this
                      course. Maximum of {MAX_REGISTRATION_CAP}.
                    </FormHelperText>
                  </FormControlRoot>
                )}
                {format === "lms-scormcloud" &&
                  learnerUrl != null &&
                  learnerUrl !== "" && (
                    <FormControlRoot>
                      <Typography sx={{ mb: 1 }}>
                        Link to share with learners to access the course.
                      </Typography>
                      <CopyToClipboardField
                        value={learnerUrl}
                        fullWidth
                        helperText="Anyone on the internet with this link will be able to launch the course."
                      />
                    </FormControlRoot>
                  )}
                {!useNewSettingsLayout && AssetSettingsOptions}
                {(format.startsWith("lms") || format.startsWith("scorm")) &&
                  rolloutScormIntroSummary && (
                    <FormControlRoot id="cool">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        id="summary-html-label"
                      >
                        Course Summary
                      </Typography>

                      {scormExcludeSummarySection ? (
                        <Alert severity="warning" sx={{ mt: 1 }}>
                          The summary section is currently excluded from the
                          export. Enable the summary section in the export
                          settings to edit the course summary.
                        </Alert>
                      ) : (
                        <MinimalHtmlEditor
                          data-cy="export-course-course-summary-html-editor"
                          data-id="export-course-summary-editor"
                          html={summaryHtml}
                          onChange={setSummaryHtml}
                          onBlur={(html: string) => {
                            saveSummaryHTML(html)
                          }}
                        />
                      )}
                    </FormControlRoot>
                  )}
                {rolloutTranslation && (
                  <TranslationControls
                    courseId={courseId}
                    courseHtml={courseHtml}
                    summaryHtml={summaryHtml}
                    translationLanguageCode={translationLanguageCode}
                    handleChangeTranslationLanguageCode={
                      handleChangeTranslationLanguageCode
                    }
                  />
                )}
              </form>
            )}
            {useNewSettingsLayout && tabValue === ASSET_SETTINGS && (
              <form noValidate autoComplete="off">
                {AssetSettingsOptions}
              </form>
            )}

            {tabValue === BRANDING && (
              <Box marginRight={2}>
                <Box
                  display="flex"
                  marginBottom={1}
                  marginTop={1}
                  flexDirection="row-reverse"
                >
                  <Button
                    disabled={isEmpty(overrides) && isEmpty(branding.changes)}
                    variant="outlined"
                    onClick={restoreDefaultBranding}
                  >
                    Restore Defaults
                  </Button>
                </Box>
                <BrandingForm
                  branding={branding}
                  onPropertyChange={onPropertyChange}
                  googleFonts={googleFonts}
                  recentFonts={recentFonts}
                  updateRecentFonts={updateRecentFonts}
                />
              </Box>
            )}
          </Box>
          <Divider
            sx={{
              marginTop: 2,
              marginBottom: 2,
            }}
          />
          {noExportMessage && (
            <Alert severity="warning" color="warning" sx={{ marginBottom: 2 }}>
              {noExportMessage}
            </Alert>
          )}

          <Box display="flex" justifyContent="space-between" marginBottom={6}>
            <Button
              variant="outlined"
              onClick={(e) => {
                e.preventDefault()
                onExit()
              }}
            >
              Close
            </Button>
            <Box display="flex" gap={1}>
              {showAccessibilityWarning && (
                <AccessibilityWarning elements={inaccessibleElements} />
              )}
              {rolloutScormPreview && (
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault()
                    handleShowPreview()
                    trackPreviewButtonClick()
                  }}
                  startIcon={<PreviewIcon />}
                  disabled={!canPreview || isLoading}
                >
                  Preview
                </Button>
              )}
              <Button
                data-cy="export-screen-export-course-button"
                variant="contained"
                color="primary"
                onClick={(e) => {
                  handleExportAssets(e)
                  trackExportButtonClick()
                }}
                disabled={!canExport || isLoading}
                startIcon={<FileDownloadIcon />}
              >
                Export Course
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <LearnerLinkDialog
        courseTitle={courseTitle}
        url={learnerUrl}
        open={showLearnerLink}
        onClose={() => {
          setShowLearnerLink(false)
          onExit()
        }}
      />
      <LmsExportDialog
        courseTitle={courseTitle}
        url={lmsExportLink}
        showLmsDeleteWarning={showLmsDeleteWarning}
        open={showLmsExportLink}
        onClose={() => {
          setShowLmsExportLink(false)
          onExit()
        }}
      />

      <LmsExportReplaceDialog
        open={showLmsReplaceDialog}
        publishedCourse={publishedCourse}
        onClose={() => {
          setShowLmsReplaceDialog(false)
        }}
        onCreateNew={() => handleReplaceSkillJar(false)}
        onReplace={() => handleReplaceSkillJar(true)}
      />
    </Container>
  )
}

/**
 * A minimalistic HTML editor
 * @param props - The props for the component
 * @param props.html - The HTML content to display in the editor
 * @param props.onChange - The function to call when the HTML content changes
 * @param props.onBlur - The function to call when editor loses focus
 */
function MinimalHtmlEditor(props: {
  html: string
  onChange: (html: string) => void
  onBlur: (html: string) => void
}) {
  return (
    <FroalaEditor
      tag="textarea"
      config={{
        ...minimalFroalaConfig,
        events: {
          /**
           * Invoke parent callback when input loses focus
           */
          blur: function () {
            const editor = this as any
            const html = editor.html.get()
            props.onBlur(html)
          },
        },
      }}
      model={props.html}
      onModelChange={props.onChange}
    />
  )
}

export default ExportScreen
